<template>
  <div id="app">
    <Header />
    <Flag />
    <router-view />
  </div>
</template>

<script>
import Header from "./components/parts/Header";
import Flag from "./components/parts/Flag";
import Content from "./components/parts/Content";
import VueRouter from "vue-router";

const component_data = {
  home_data: {
    header: `Hello.`,
    content: [
      `I'm Lily. I'm a developer currently living in germany.`,
      `I love modding and hacking away at things, especially games!`,
      `I am also an avid Twitch viewer and moderator.`,
    ]
  },
  portfolio_data: {
    header: `Portfolio`,
    content: [
      `<h2>Languages</h2>`,
      `Learning C# since 2016, actively developing since then`,
      `Used Java for a few years during Uni and School`,
      `A bit of JS I picked up over the years`,
      `Rudimentary experience in Lisp (Scheme dialect), Python, Node.js, Bash`,
      `Also somewhat comfortable with x86 assembly, thanks to reverse engineering misadventures`,
      `<br>`,
      `<h2>Projects</h2>`,
      `Contributor to the <a href="https://github.com/EverestAPI/Everest">EverestAPI</a> modloader for Celeste`,
      `Major Contributor to the <a href="https://gamebanana.com/mods/424541">Strawberry Jam Collab</a> for Celeste`,
      `<a href="https://github.com/lollyde/CelesteRandomizer">Celeste Randomizer</a>, a mod for Celeste using the EverestAPI`,
      `A self-hosted media server, utilizing docker-compose and Ansible`,
      `A couple of simple Twitch chatbots implemented using <a href="https://github.com/TwitchLib/TwitchLib">TwitchLib</a>`,
      `<a href="https://github.com/lollyde/personal-website">This website</a>, using Vue.js`,
      `"Nine men's morris" AI written in Java (Uni project)`,
      `"Organization Helper" app for Android + backend developed in Java (Uni project)`,
      `Sadly I cannot provide the source code for my Uni projects.`,
      `Other projects can be found on <a href="https://github.com/lollyde">github</a>`
    ]
  },
  contact_data: {
    header: `Contact`,
    content: [
      `I can be reached via a number of ways:`,
      `Email: <a href="mailto:Lily@lollyde.online">Lily@lollyde.online</a> or <a href="mailto:lollyde@protonmail.com">lollyde@protonmail.com</a>`,
      `Discord: Lollyde#9660`
    ]
  },
  about_data: {
    header: `About`,
    content: [
      `This site was built using <a href="https://vuejs.org/">Vue.js</a>`,
      `Background made using a modified version of <a href="https://vincentgarreau.com/particles.js/">particles.js</a>`,
      `This website is hosted on my own server with <a href="https://nginx.org/">Nginx</a>`
    ]
  },
  notfound_data: {
    header: `404`,
    content: [`Requested page wasnt found :(`]
  }
};

const router = new VueRouter({
  mode: "history",
  base: __dirname,
  routes: [
    {
      path: "/",
      component: Content,
      props: () => ({
        content: component_data.home_data.content,
        header: component_data.home_data.header
      })
    },
    {
      path: "/portfolio",
      component: Content,
      props: () => ({
        content: component_data.portfolio_data.content,
        header: component_data.portfolio_data.header
      })
    },
    {
      path: "/contact",
      component: Content,
      props: () => ({
        content: component_data.contact_data.content,
        header: component_data.contact_data.header
      })
    },
    {
      path: "/about",
      component: Content,
      props: () => ({
        content: component_data.about_data.content,
        header: component_data.about_data.header
      })
    },
    {
      path: "*",
      component: Content,
      props: () => ({
        content: component_data.notfound_data.content,
        header: component_data.notfound_data.header
      })
    }
  ]
});

export default {
  router,
  name: "app",
  components: {
    Header,
    Flag
  }
};
</script>

<style>
html {
  background-color: #1e1e1e;
  color: lightgrey;
}

body {
  margin: 0;
}

a {
  text-decoration: none;
  color: #42a5f5;
  background: inherit;
}
</style>
